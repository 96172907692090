import { SelectOptionType } from '@octano/global-ui';
import { ReactElement, useCallback } from 'react';

import { getTariffFormData } from '../../../../../api/requests/tuitionProcess';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { createFetchContext } from '../../../../../providers/FetchContextFactory';
import {
  Quota,
  TariffFormData,
  TariffPaymentMethodSettingValue,
} from '../../../../../types/tariffTypes';
import { formatOptions } from '../../../../../utils/selectFormat';

export interface BaseLoaderProps {
  children: (props: {
    selectOptions: {
      quotaOptions: SelectOptionType[];
      paymentMethodsOptions: SelectOptionType[];
      tariffPaymentTypesOptions: SelectOptionType[];
      documentTypesOptions: SelectOptionType[];
      businessActivitiesOptions: SelectOptionType[];
    };
    settings: {
      dateLocalOffset: string;
      paydaySetting: { value: number; description: string };
      paymentMethodSettingValue: TariffPaymentMethodSettingValue;
      tuitionFree: boolean;
    };
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  TariffFormData
>();

export const BaseLoaderConsumer = FetchConsumer;

const purgeAndMapQuotas = (quotas?: Quota[]) => {
  if (!quotas?.length) {
    return [];
  }
  const nextQuotas: Quota[] = [];
  for (const quota of quotas) {
    if (!nextQuotas.some((q) => q.quota === quota.quota)) {
      nextQuotas.push(quota);
    }
  }

  return nextQuotas.map((quota) => ({
    value: quota.id,
    label: quota.quota,
  }));
};

/**
 *
 * Base Loader debe contener toda la data necesaria para que el formulario funcione.
 * opciones de select, settings, etc.
 */
export default function BaseLoader({ children }: BaseLoaderProps) {
  const request = useCallback(() => {
    return getTariffFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            selectOptions: {
              quotaOptions: purgeAndMapQuotas(data.quotas),
              documentTypesOptions: formatOptions(data.documentTypes),
              paymentMethodsOptions: data.paymentMethods.flatMap<SelectOptionType>(
                (objeto) => {
                  const { id, name, modalityToPaymentMethod } = objeto;
                  return modalityToPaymentMethod.map((elemento) => ({
                    value: id,
                    label: name,
                    paymentType: elemento.paymentType,
                  }));
                },
              ),
              tariffPaymentTypesOptions: formatOptions(data.tariffPaymentTypes),
              businessActivitiesOptions: formatOptions(data.businessActivities),
            },
            settings: {
              dateLocalOffset: data.dateLocalOffset,
              paydaySetting: data.paydaySetting,
              paymentMethodSettingValue: data.paymentMethodSettingValue,
              tuitionFree: data?.tuitionFree,
            },
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
